@import url("https://fonts.googleapis.com/css2?family=Bellota+Text:wght@300;400;700&family=Monoton&display=swap");

/* body {
  min-height: 100vh;
  display: flex;
  align-items: center;
  background-color: rgb(233, 150, 150);
  font-family: 'Open Sans'
} */

#demo {
  background: linear-gradient(112deg, #ffffff 50%, antiquewhite 50%);
  max-width: 1400px;
  margin-bottom: 30px;
  /* margin: auto; */
  /* transition: all 0.5s; */
  /* max-height: 400px; */
  font-family: "Bellota Text", "cursive";
  font-weight: bolder;
}
.back-set {
  background-color: #202b37;
  padding-top: 30px;
  height: auto;
  padding-bottom: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.carousel-caption {
  position: initial;
  z-index: 10;
  padding: 4rem 4rem;
  color: rgba(0, 0, 0, 0.856);
  text-align: center;
  font-size: 1.2rem;
  /* font-style: italic; */
  font-weight: bolder;
  line-height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@media (max-width: 767px) {
  .carousel-caption {
    position: initial;
    z-index: 10;
    padding: 3rem 2rem;
    color: rgba(78, 77, 77, 0.856);
    text-align: center;
    font-size: 0.7rem;
    font-style: italic;
    font-weight: bold;
    line-height: 1.5rem;
  }
}

.carousel-caption img {
  width: 6rem;
  border-radius: 5rem;
  margin-top: 2rem;
}

@media (max-width: 767px) {
  .carousel-caption img {
    width: 4rem;
    border-radius: 4rem;
    margin-top: 1rem;
  }
}

#image-caption {
  font-style: normal;
  font-size: 1rem;
  margin-top: 0.5rem;
}

@media (max-width: 767px) {
  #image-caption {
    font-style: normal;
    font-size: 0.6rem;
    margin-top: 0.5rem;
  }
}

.i {
  background-color: #202b37;
  /* background-color: rgb(223, 56, 89); */
  padding: 1.4rem;
}
@media (max-width: 767px) {
  .i {
    padding: 0.8rem;
  }
}

.carousel-control-prev {
  justify-content: flex-start;
}

.carousel-control-next {
  justify-content: flex-end;
}

.carousel-control-prev,
.carousel-control-next {
  transition: none;
  opacity: unset;
}

/* =========================== */
.aoe-title {
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
  color: #f0ede7;
}
.line {
  width: 75px;
  height: 5px;
  background-color: #a1989e;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 70px;
}
