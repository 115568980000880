/* .top-content {
  margin-top: 40px;
  padding-bottom: 30px;
  overflow-x: hidden;
  
}

.carousel {
  padding: 0px 40px;
  margin-bottom: 40px;
}

@media (max-width: 767px) {
  .carousel {
    padding: 0;
  }
  

  .top-content .carousel-control-prev,
  .top-content .carousel-control-next {
    display: none;
  }
  .top-content .carousel-indicators li {
    margin-left: 10px;
    margin-right: 10px;
  }

 
} */

.vid-container {
  width: 100%;
  margin: 40px 0px;
  padding: 10px;
}
.vid {
  width: 30%;
  display: flex;
  align-items: center;
}

.vid iframe {
  width: 100%;
  height: 400px;
}

.vid-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

@media (max-width: 1400px) {
  .vid iframe {
    height: 300px;
  }
}

@media (max-width: 1000px) {
  .vid iframe {
    height: 200px;
  }
}

@media (max-width: 700px) {
  .vid-wrapper {
    flex-direction: column;
    align-items: center;
  }
  .vid {
    width: 80%;
    display: flex;
    align-items: center;
  }

  .vid iframe {
    width: 100%;
    height: auto;
  }
}

/* .vid:hover {
  background: rgba(255, 255, 255, 0.5);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
  cursor: pointer;
} */
/* 
.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px; 
  background: #fff;

  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide .video {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
} */
