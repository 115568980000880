.itag {
    opacity: 0;
    font-size: 18px;
    color: #fff;
    will-change: transform;
    -webkit-transform: scale(0.1);
    transform: scale(0.1);
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
.heading2 {
    pointer-events: none;
}
.container2 {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.container2 .card3 {
    position: relative;
    width: 100%;
    height: 300px;
    background-color: #fff;
    overflow: hidden;
    margin-bottom: 4px;
}
.container2 .card3:before {
    content: "";
    z-index: 99;
    position: absolute;
    top: -10px;
    left: 32px;
    display: block;
    width: 16px;
    height: 16px;
    border-radius: 16px;
    background-color: #e6e5e1;
}
.container2 .card3:after {
    content: "";
    z-index: 99;
    position: absolute;
    bottom: -10px;
    left: 32px;
    display: block;
    width: 16px;
    height: 16px;
    border-radius: 16px;
    background-color: #e6e5e1;
}
.container2 .card3 .unlist {
    z-index: 99;
    position: absolute;
    left: 39px;
    top: 5px;
    list-style-type: none;
}
.container2 .card3 .unlist .listi {
    width: 2px;
    height: 2px;
    border-radius: 2px;
    margin: 6px 0;
    background-color: #e6e5e1;
}
.container2 .card3 .heading2 {
    z-index: 99;
    font-family: "Poppins", sans-serif;
    position: absolute;
    bottom: 0;
    font-size: 50px;
    font-weight: 700;
    color: #fff;
}
.container2 .card3 .fa-arrow-right {
    z-index: 100;
    position: absolute;
    right: 75px;
    bottom: 25px;
    font-size: 40px;
    cursor: pointer;
}
.container2 .card3 .pic {
    z-index: 100;
    width: 100%;
    height: 100%;
    align-items: center;
    background-image: url("https://images.unsplash.com/photo-1528785198459-ec50485704c7?ixlib=rb-0.3.5&s=3a2fc3039516555bbb2e9cd2967bd321&auto=format&fit=crop&w=1537&q=80");
    background-size: 100% 100%;
    /* filter: grayscale(80%); */
}
.container2 .card3 .social {
    position: absolute;
    left: 60px;
    top: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 180px;
    height: 64px;
    border-radius: 80px;
}
.container2 .card3 .social i:nth-of-type(1) {
    -webkit-transition-delay: 0.4s;
    transition-delay: 0.4s;
}
.container2 .card3 .social i:nth-of-type(2) {
    -webkit-transition-delay: 0.3s;
    transition-delay: 0.3s;
}
.container2 .card3 .social i:nth-of-type(3) {
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
}
.container2 .card3 .social i:nth-of-type(4) {
    -webkit-transition-delay: 0.1s;
    transition-delay: 0.1s;
}
.container2 .card3:hover .itag {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}
.container2 .card3 .butn {
    position: absolute;
    right: 14px;
    bottom: 14px;
    width: 30px;
    height: 30px;
    background-color: #da4d1d;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    outline: none;
    transition: all 0.3s ease;
    mix-blend-mode: hard-light;
}
.container2 .card3 .butn .itag {
    font-size: 3rem;
}
.container2 .card3:hover .butn {
    transform: scale(16.5);
}
.container2 .card3:hover p {
    color: #fff;
}
.container2 .card3:hover .pic {
    filter: grayscale(0);
}
.container2 .card2 .butn {
    background-color: #2b26c3;
}
.dr {
    position: absolute;
    bottom: 16px;
    right: 16px;
    width: 100px;
}
