/* ===============================Modal CSS==================================== */
@keyframes modalo {
  from {
    width: 0;
    height: 0;
  }
  to {
    width: 100%;
    height: 100%;
  }
}

.modal {
  position: fixed;
  z-index: 20000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.541);
  display: flex;
  justify-content: center;
  align-items: center;
  animation-name: modalo;
  animation-duration: 0.2s;
  animation-direction: alternate;
  padding: 0;
}

.closed-modal {
  display: none;
}

.modal-content {
  width: 95%;
  height: 80%;
  background: transparent;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-img {
  /* width: 80%;
    height: 100%;
    display: block; */
  width: 70%;
}
@media (max-width: 700) {
  .modal-content {
    top: 30%;
    width: 100%;
    height: 80%;
  }
  .modal-img {
    /* width: 80%;
      height: 100%;
      display: block; */
    height: auto;
  }
}

.close-btn {
  margin-top: 10px;
  margin-right: 6px;
  position: fixed;
  top: 0px;
  right: 0;
  color: #fff;
}
.download-btn {
  margin-top: 70px;
  margin-right: 6px;
  right: 0;
  top: 0;
  position: fixed;
  color: #fff;
  z-index: 10000;
}
.more-info {
  position: fixed;
  bottom: 10px;
}
.noti {
  z-index: 60000;
}
