.fh5co-counters {
  /* padding: 2em 0; */

  position: relative;

  /* background-color: #56cfe1; */
  background-color: #202b37;
  height: 100%;
  text-align: center;
  padding: 3% 1%;
}
.fh5co-counters .row {
  display: flex;
  align-items: center;
  justify-content: center;
}
.fh5co-counters .overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: "";
  background: rgba(0, 0, 0, 0.5);
}
.fh5co-counters .counter-wrap {
  border: 1px solid red !important;
}
.fh5co-counters .fh5co-counter {
  font-size: 44px;
  display: block;
  color: white;
  font-family: "Roboto Slab", serif;
  width: 100%;
  font-weight: 400;
  /* margin-bottom: .1em; */
}
.fh5co-counters .fh5co-counter-label {
  color: rgba(255, 255, 255, 0.8);
  font-size: 18px;
  /* margin-bottom: 2em; */
  display: block;
  font-family: "Roboto Slab", serif;
}
.fh5co-counters .icon i {
  font-size: 45px;
  color: #fff;
}

/* #skew2 {
  background: #ff3c38;

  -webkit-background-size: cover;
  background-size: cover;
  background-position: center center;

  z-index: 9;
}
.cus-div:after {
  position: absolute;
  width: 100%;
  height: 100%;
  background: inherit;
  z-index: -1;
  bottom: 0;
  content: "";
}
#skew2:after {
  transform-origin: right bottom;
  transform: skewY(-3deg);
  background: #ff3c38;
} */
