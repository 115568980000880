.chatButton {
  /* position: fixed;
  bottom: 10px;
  right: 10px;
  height: 80px;
  width: 80px; */
  z-index: 10001;
}

df-messenger {
  --df-messenger-button-titlebar-color: #ffffff;
  --df-messenger-button-titlebar-font-color: #000;
}

.chatBox {
  position: fixed;
  bottom: 0px;
  right: 0px;
  z-index: 3000;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.scrolling-wrapper {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
}
.card {
  display: inline-block;
}
.backcolor {
  position: relative;
}
