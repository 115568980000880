@import url("https://fonts.googleapis.com/css2?family=Bellota+Text:wght@300;400;700&family=Monoton&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700;900&display=swap");
* {
  /* font-family: "Bellota Text", "cursive"; */
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}
body {
  background-color: #f5f5f3;
  scroll-behavior: smooth;
}
html {
  scroll-behavior: smooth;
}

.banner {
  width: auto;
  height: auto;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.bannner-img {
  /* width: auto;
  height: 200px; */
  /* margin-right: 20px;
  margin-left: 20px; */
}
.contact-info-cont {
  margin: 0;
  margin-top: 0;
  width: 100%;
  background: #fff;
  color: #000;
  padding: 5px;
  display: flex;
  flex-direction: row;
  font-size: 12px;
}
.phone {
  /* border-left: 1px solid #fff; */
  margin-left: 20px;
  padding: 3px;
  /* color: #fff; */
}
.email {
  /* border-left: 1px solid #fff; */
  margin-left: 20px;
  padding: 3px;
  /* color: #fff; */
}
@media (max-width: 991px) {
  .banner {
    height: auto;
  }
  .bannner-img {
    width: 100%;
  }
}
