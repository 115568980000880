.about-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 0;
  height: 70vh;
  margin: 40px 0;
}

.info {
  color: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 50px;
  margin: 50px 0;
  border-right: #202b37 solid 5px;
}
.info p {
  font-size: 1rem;
  letter-spacing: 0.1rem;
  line-height: 2rem;
  text-align: justify;
}
.info .title {
  font-size: 2rem;
  font-weight: 700;
  letter-spacing: 0.1rem;
  color: #202b37;
  margin-bottom: 50px;
}

.solid-sec {
  /* background-color: #202b37; */
  display: flex;
  align-items: center;
  border-radius: 0rem 2rem 2rem 0rem;

  margin: 50px;
}
.solid-sec img {
  margin: auto;
  width: auto;
  height: 100%;
  border-radius: 2rem;
}

@media (max-width: 1300px) {
  .about-wrapper {
    height: 70vh;
    /* margin: 40px 0; */
    /* padding: 40px; */
  }
  .info {
    padding: 0 30px;
    margin: 30px 0;
    border-right: #202b37 solid 4px;
  }
  .info p {
    font-size: 1rem;
    line-height: 2rem;
  }
  .info .title {
    margin-bottom: 40px;
  }

  .solid-sec {
    /* background-color: #202b37; */
    display: flex;
    align-items: center;
    border-radius: 0rem 2rem 2rem 0rem;

    margin: 30px;
  }
}

@media (max-width: 1000px) {
  .about-wrapper {
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 0;
    height: auto;
    margin: 20px 0px;
    padding: 20px;
  }
  .info {
    padding: 0 30px;
    margin: 30px 0;
    border-right: none;
  }
  .info p {
    font-size: 0.8rem;
    line-height: 1.5rem;
  }
  .info .title {
    margin-bottom: 40px;
  }
  .solid-sec {
    /* background-color: #202b37; */

    margin: 0px;
    padding: 0px;
  }
  .solid-sec img {
    /* margin: 20px; */
    width: 100%;
    height: 70%;
    border-radius: 2rem;
  }
}
