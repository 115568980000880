/* .pdf-modal {
  display: flex;
  justify-content: center;
  align-items: center;
} */
.pdf {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
