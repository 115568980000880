.givmargin {
  margin: 0 30px;
}

@media (max-width: 991px) {
  .givmargin {
    margin: 0 10px;
  }
}

.update-img {
  position: relative;
}
.folll {
  overflow: hidden;
}
/* .cus-div {
  width: 100%;
  position: relative;
}
#skew1 {
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center center;
  z-index: 10;
} */
/* @media (max-width: 991px) {
  #skew1 {
    margin-top: 30%;
    padding: 0;
  }
} */
/* .cus-div:after {
  position: absolute;
  width: 100%;
  height: 80%;
  background: inherit;
  z-index: -1;
  bottom: 0;
  content: "";
}

#skew1::after {
  transform-origin: left bottom;
  transform: skewY(3deg);
  background: #ffffff;
  height: 50%;
} */

.reports-title {
  text-transform: uppercase;
  text-align: center;
  font-weight: 600;
  color: #000000;
  padding: 0;
  margin: 0;
}
.reports-line {
  width: 150px;
  height: 5px;
  background-color: #ff0040;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 25px;
}
