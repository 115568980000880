.activity-card {
    max-height: 100%;
    
}
.activity-content {
    position: absolute;
}

  
  .cr-img {
      object-fit: fill;
      width:25vw;
      height: 40vh;
      
  }
  .heading{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
  }
  .main-card {
      border-top: 1px solid black;
  }