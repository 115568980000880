
.words{
    font-family: Krona;
}

.hod{
    background: linear-gradient(169deg, #000000, #e45050);
    background-size: 400% 400%;

    -webkit-animation: AnimationName 10s ease infinite;
    -moz-animation: AnimationName 10s ease infinite;
    -o-animation: AnimationName 10s ease infinite;
    animation: AnimationName 10s ease infinite;
}

@-webkit-keyframes AnimationName {
    0%{background-position:26% 0%}
    50%{background-position:75% 100%}
    100%{background-position:26% 0%}
}
@-moz-keyframes AnimationName {
    0%{background-position:26% 0%}
    50%{background-position:75% 100%}
    100%{background-position:26% 0%}
}
@-o-keyframes AnimationName {
    0%{background-position:26% 0%}
    50%{background-position:75% 100%}
    100%{background-position:26% 0%}
}
@keyframes AnimationName {
    0%{background-position:26% 0%}
    50%{background-position:75% 100%}
    100%{background-position:26% 0%}
}