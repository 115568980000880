* {
  margin: 0;
  padding: 0;
}
.checkbox {
  border-radius: 10%;
}
.form .button,
.form .message,
.customSelect,
.form .select,
.form .textarea,
.form .text-input,
.form .option-input + label,
.form .checkbox-input + label,
.form .label {
  padding: 0.75em 1em;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  line-height: normal;
  border-radius: 0;
  border: none;
  background: none;
  display: block;
}
.form .checkbox-input + label {
  border-radius: 50%;
}
.form .label {
  font-weight: bold;
  color: #fff;
  padding-top: 0;
  padding-left: 0;
  letter-spacing: 0.025em;
  font-size: 1.125em;
  line-height: 1.25;
  position: relative;
  z-index: 100;
}
.required .form .label:after,
.form .required .label:after {
  content: "*";
  color: #000000;
  font-weight: normal;
  font-size: 0.75em;
  vertical-align: top;
}

.customSelect,
.form .select,
.form .textarea,
.form .text-input,
.form .option-input + label,
.form .checkbox-input + label {
  font: inherit;
  line-height: normal;
  width: 100%;
  box-sizing: border-box;
  background: #fff;
  color: #000;
  position: relative;
}

.bgccc {
  background-color: #202b37;
  /* background-color: #484349; */
  /* background: rgb(255, 60, 56);
  background: linear-gradient(
    180deg,
    rgba(255, 60, 56, 1) 0%,
    rgba(255, 151, 148, 1) 40%,
    rgba(255, 255, 255, 1) 100%
  ); */
  margin: 50px 400px;
  padding: 30px;
  border-radius: 40px;
}

@media (max-width: 991px) {
  .bgccc {
    margin: 15px 0px;
    border-radius: 10px;
    padding: 30px 10px;
  }
}

.customSelect:placeholder,
.form .select:placeholder,
.form .textarea:placeholder,
.form .text-input:placeholder,
.form .option-input + label:placeholder,
.form .checkbox-input + label:placeholder {
  color: white;
}
.customSelect:-webkit-autofill,
.form .select:-webkit-autofill,
.form .textarea:-webkit-autofill,
.form .text-input:-webkit-autofill,
.form .option-input + label:-webkit-autofill,
.form .checkbox-input + label:-webkit-autofill {
  box-shadow: 0 0 0px 1000px #111111 inset;
  -webkit-text-fill-color: white;
  border-top-color: #111111;
  border-left-color: #111111;
  border-right-color: #111111;
}

.form .message {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 100;
  font-size: 0.625em;
  color: white;
}

.form .option-input,
.form .checkbox-input {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.form .option-input + label,
.form .checkbox-input + label {
  display: inline-block;
  width: auto;
  color: #4e4e4e;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}
.form .option-input:focus + label,
.form .checkbox-input:focus + label,
.form .option-input:active + label,
.form .checkbox-input:active + label {
  color: #4e4e4e;
}
.form .option-input:checked + label,
.form .checkbox-input:checked + label {
  color: white;
  background-color: #ff3c38;
}

.form .button {
  font: inherit;
  line-height: normal;
  cursor: pointer;
  background: #ff3c38;
  color: white;
  font-weight: bold;
  width: auto;
  margin-left: auto;
  font-weight: bold;
  padding-left: 3em;
  padding-right: 3em;
  border-radius: 10px;
}
.form .button:hover,
.form .button:focus,
.form .button:active {
  color: white;
  border-color: white;
}
.form .button:active {
  position: relative;
  top: 1px;
  left: 1px;
}

.form {
  max-width: 40em;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-end;
}
.form .field {
  width: 100%;
  margin: 0 0 1.5em 0;
}
@media screen and (min-width: 40em) {
  .form .field.half {
    width: calc(50% - 1px);
  }
}
.form .field.last {
  margin-left: auto;
}
.form .textarea {
  max-width: 100%;
}
.form .select {
  text-indent: 0.01px;
  text-overflow: "" !important;
}
.form .select::-ms-expand {
  display: none;
}
.form .checkboxes,
.form .options {
  padding: 0;
  margin: 0;
  list-style-type: none;
  overflow: hidden;
}
.form .checkbox,
.form .option {
  float: left;
  margin: 1px;
}
.customSelect {
  pointer-events: none;
}
.customSelect:after {
  content: "";
  pointer-events: none;
  width: 0.5em;
  height: 0.5em;
  border-style: solid;
  border-color: white;
  border-width: 0 3px 3px 0;
  position: absolute;
  top: 50%;
  margin-top: -0.625em;
  right: 1em;
  transform-origin: 0 0;
  transform: rotate(45deg);
}
.customSelect.customSelectFocus:after {
  border-color: white;
}
.magic-focus {
  position: absolute;
  z-index: 0;
  width: 0;
  pointer-events: none;
  background: rgba(255, 255, 255, 0.15);
  transition: top 0.2s, left 0.2s, width 0.2s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  will-change: top, left, width;
  transform-origin: 0 0;
}
