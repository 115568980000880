.pdf-modal {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.377);
  position: fixed;
  overflow: scroll;
  z-index: 80000;
}
.close-btn {
  margin-right: 6px;
  position: fixed;
  top: 0;
  right: 0;
  color: #fff;
  z-index: 80001;
}
