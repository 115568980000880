@import url("https://fonts.googleapis.com/css?family=Libre+Caslon+Text:400,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Crimson+Text:wght@400;600&family=Lato&family=Montserrat&family=Open+Sans&family=Roboto&display=swap");

.logo {
  transition: 500ms;
}

.logo:hover {
  transform: scale(1.3, 1.3);
}
/*           ///////////////////////////////////////////////////////// */

.aaa {
  background-color: #202b37;
}

.txt-color {
  color: #fff1d0;
}

.headerr {
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.6)),
    url("./sguni.jpg");
  background-size: cover;
  background-position: center;
  height: 100vh;
}

.hero-text-box {
  margin-left: 10px;
  position: relative;
  max-width: 1140px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.hero-text-box h1 {
  margin-top: 0;
  margin-bottom: 20px;
  color: #fff;
  font-size: 400%;
  word-spacing: 10px;
  letter-spacing: 4px;
  line-height: 100px;
}

@media only screen and (max-width: 767px) {
  .hero-text-box {
    /* width: 400px; */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .hero-text-box h1 {
    margin-bottom: 0px;
    font-size: 200%;
    word-spacing: 6px;
    letter-spacing: 3px;
    line-height: 50px;
  }
}

/*  vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv */

.container .image {
  position: absolute;
  width: 100vw;
  height: 100vh;
}

.container .menu-icon {
  position: absolute;
  color: #282b28;
  font-size: 30px;
  left: 42px;
  top: 24px;
  cursor: pointer;
  z-index: 1000;
}

.menu-container {
  height: 0%;
  width: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1000;
  background: #fff1d0;
  overflow: hidden;
  transition: 1s ease-in-out;
}

.menu-content {
  position: relative;
  top: 25%;
  width: 100%;
  text-align: center;
  margin-top: 30px;
}

.menu-content a {
  padding: 16px;
  text-decoration: none;
  font-size: 18px;
  color: #282b28;
  display: block;
  font-family: sans-serif;
  transition: 0.3s ease-in-out;
}

.menu-content a:hover {
  background-color: #1b998b;
}

.menu-container .closebtn {
  position: absolute;
  top: 36px;
  right: 48px;
  font-size: 30px;
  color: #fff1d0;
  z-index: 1000;
  cursor: pointer;
}

.about-sec {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
