.popup {  
    position: fixed;  
    width: 100%;  
    height: 100%;  
    top: 0;  
    left: 0;  
    right: 0;  
    bottom: 0;  
    margin: auto;  
    background-color: rgba(0, 0, 0, 0.411);  
    overflow-x: scroll;
    opacity: 1.5;        
  }  
  .popup\_inner {  
    position: absolute;  
    left: 25%;  
    right: 25%;  
    top: 25%;  
    bottom: 25%;  
    margin: auto;  
    border-radius: 20px;  
    background: white;  
    height: 100%;
  }
  .head{
      color: #ffffff;
  }

  .btn-block {
    width: 100%;
}